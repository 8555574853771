import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Integrating Polis with Citizens Assemblies`}</h1>
    <h4>{`A guide for those who are thinking about the interaction between techniques for listening at scale and in-person deliberation`}</h4>
    <p><em parentName="p">{`This guide was written by Liz Barry, Head of Partnerships at the Computational Democracy Project. We gratefully acknowledge major contributions by Alex Renirie and Linn Davis of Healthy Democracy, Simon Wright of Trust Democracy, and Andy Paice of Shared Future, with review by Peter McLeod. Thank you to all early readers.`}</em></p>
    <p><em parentName="p">{`The Computational Democracy Project is supported by One Project to equip facilitators of citizens assemblies to use Polis. Please send inquiries and feedback to `}<a parentName="em" {...{
          "href": "mailto:hello@compdemocracy.org"
        }}>{`hello@compdemocracy.org`}</a>{`.`}</em></p>
    <p>{`This guide contains six strategies for integrating the Polis platform with lottery-selected citizen assemblies. These strategies aim to broaden public input and enhance the overall effectiveness of citizen assemblies by leveraging Polis.`}</p>
    <h3><strong parentName="h3">{`Background`}</strong></h3>
    <p>{`Polis is an open source, real-time system for gathering, analyzing and understanding what large groups of people think in their own words, enabled by advanced statistics and machine learning. A citizens’ assembly, according to the OECD, is a long form deliberation made up of randomly selected members representing the demographic make-up of the jurisdiction the assembly purports to represent. Assemblies hear from a range of experts and other stakeholders while working towards a series of recommendations prioritized in order of agreement which are then conveyed to government. Because of the range of voices, sources of expertise and duration, assemblies are especially well suited to working through complex issues involving trade-offs and various kinds of priority-setting exercises. (`}<a parentName="p" {...{
        "href": "https://www.oecd.org/gov/innovative-citizen-participation-and-new-democratic-institutions-339306da-en.htm"
      }}>{`OECD 2020`}</a>{`)`}</p>
    <p>{`Polis is designed to be a step in a process, a utility for facilitators that is typically preceded by engagement (construction of publics) and followed by action (problem solving / making recommendations). `}</p>
    <p><strong parentName="p">{`At what points in a process can Polis best be used?`}</strong>{` `}</p>
    <ul>
      <li parentName="ul">{`✅ `}<strong parentName="li">{`Early:`}</strong>{` Polis serves as a large intake collector, recruiting participants across multiple issues`}</li>
      <li parentName="ul">{`✅ `}<strong parentName="li">{`Middle:`}</strong>{` Polis enables understanding of the breadth of opinion across an issue`}</li>
      <li parentName="ul">{`❌ `}<strong parentName="li">{`Late:`}</strong>{` Polis is not able to affix a stamp of approval; instead, where available, use a ballot referendum or make demands of formal power.`}</li>
    </ul>
    <p><strong parentName="p">{`Can Polis be used with other in-person deliberative processes besides lottery-selected citizen assemblies?`}</strong>{` `}</p>
    <p>{`Polis has been used with many types of in-person deliberative processes. Here are a few highlights: `}</p>
    <ul>
      <li parentName="ul">{`Policy making (vTaiwan)`}</li>
      <li parentName="ul">{`Policy review (UK Gov Policy Lab, NYC DHS)`}</li>
      <li parentName="ul">{`Platform development for social movements and political parties (UK Labor Party, Aufstehen in Germany, Extinction Rebellion NYC)`}</li>
      <li parentName="ul">{`Preparation for town hall meetings (Kentucky USA)`}</li>
      <li parentName="ul">{`Focus groups combined with a nationally representative sample (Demos in UK)`}</li>
    </ul>
    <h2>{`Integrating Polis with citizen assemblies`}</h2>
    <p><strong parentName="p">{`What are some ways to connect Polis and lottery-selected citizen assemblies?`}</strong></p>
    <p>{`Here is an overview of six strategies:`}</p>
    <ol>
      <li parentName="ol">{`Begin with a preparatory phase to choose the topic`}</li>
      <li parentName="ol">{`Improve topic framing through dynamic topic refinement `}</li>
      <li parentName="ol">{`Improve representation by adding epistemic diversity as a selection criteria for members`}</li>
      <li parentName="ol">{`Assist an assembly to understand themselves and their information gaps`}</li>
      <li parentName="ol">{`Enable distributed deliberation`}</li>
      <li parentName="ol">{`Support distributed review of text`}</li>
    </ol>
    <h3>{`Six strategies, in detail:`}</h3>
    <p><strong parentName="p">{`BEFORE THE ASSEMBLY (1, 2, 3)`}</strong></p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Begin with a preparatory phase to choose the topic`}</strong>{`
`}<a parentName="p" {...{
            "href": "http://pol.is/"
          }}>{`Polis`}</a>{` can serve as an effective preliminary engagement tool prior to the convening of an in-person assembly. Polis eases the process of listening broadly by simplifying how an individual can contribute while also lowering the burden of processing the contributions into coherence. In this way, Polis enables a larger number of individuals to participate in agenda setting. The forthcoming “importance” feature in Polis can provide insight into which among all topics is the most important and urgent to take up for discussion by an assembly. `}<strong parentName="p">{`Use Polis to ask your population, “what topic should be taken up by a citizen assembly?”`}</strong>{` `}</p>
        <p parentName="li">{`   `}<strong parentName="p">{`Example:`}</strong>{` The United Nations Development Programme asked 30,000 youth in south and southeast Asia an open ended question about how they thought their countries should respond to the challenges of climate change, then presented the topics which had the highest levels of support at COP26 as priorities to their respective national governments. UNDP identifies this as the largest online dialogue to happen to-date in the global South. `}<a parentName="p" {...{
            "href": "https://compdemocracy.org/Case-studies/2020-UNDP-South+S-E-Asia/"
          }}>{`Read more`}</a>{` `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Improve topic framing through dynamic topic refinement`}</strong>{`
`}<a parentName="p" {...{
            "href": "http://pol.is/"
          }}>{`Polis`}</a>{` helps many people collectively refine how a topic is framed by revealing new perspectives, concerns, and questions. The resulting Polis opinion map enables organizers to frame the topic for an assembly in a way that highlights both shared values and key disagreements, fostering more focused and productive discussions. Framing power has huge influence over the options that assemblies consider, yet it is not standard practice for government-initiated public consultations to incorporate input from stakeholders or the broader community. `}<strong parentName="p">{`Use Polis to improve topic framing and seize this powerful opportunity to democratize this process and limit political bias in its earliest stages.`}</strong></p>
        <p parentName="li">{`   `}<strong parentName="p">{`Example:`}</strong>{` in vTaiwan, the discussion on sharing economy app Uber began with assumptions about regulating Uber, but expanded to include dimensions of the existing local taxi industry. `}<a parentName="p" {...{
            "href": "https://compdemocracy.org/Case-studies/2014-vTaiwan/"
          }}>{`Read more`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Improve representation by adding epistemic diversity as a selection criteria for members`}</strong>{`
Epistemic diversity refers to the range in how people think, including how people think about specific topics, rather than who people are categorically for instance by demographics and geography, among other categories. Incorporating both traditional demographic representation as well as epistemic diversity results in a more diverse and inclusive assembly. Customarily, potential assembly members are selected to match the demographics present in the whole population, and entire populations have only been understood `}<em parentName="p">{`en masse`}</em>{` by their demographic criteria or political party affiliation / voting habits. To ensure that an assembly is representative of the broader population in terms of demographics as well as perspectives on the topic, the distinct opinion groups and points of group-informed consensus revealed by Polis can be used to develop epistemic selection criteria. `}<strong parentName="p">{`Use Polis to ensure representation from each opinion group, alongside traditional diversity criteria that matches the population as a whole.`}</strong>{` `}</p>
        <p parentName="li">{`   `}<strong parentName="p">{`NOTE:`}</strong>{` all of the above strategies happen BEFORE THE ASSEMBLY and serve an additional purpose — to engage people via Polis who can then opt in to the democratic lottery.`}</p>
      </li>
    </ol>
    <p><strong parentName="p">{`DURING THE ASSEMBLY (4, 5, 6)`}</strong></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Assist an assembly to understand themselves, including their information gaps, and public opinion`}</strong>{`
A newly convened assembly is composed of people from varying walks of life. For the team supporting the assembly members, Polis can help identify information gaps for which educational briefings can be prepared, as well as issues that fall outside expert framings that require additional stakeholders / rightholders to testify. For the assembly members themselves, Polis helps map the opinion landscape showing where the wider public — on whose behalf they are deliberating — is at on any given issue. Unlike polling, which highlights points of divergence, the Polis method shows preferences driving towards points of convergence. The assembly can use these points of convergence as a springboard for their own deliberations. The Polis mapping preserves points of disagreement and high levels of uncertainty around crucial topics, which indicate that more resources of various types, including time for deliberation, should be provisioned to the assembly. `}<strong parentName="p">{`Use Polis to help assembly members more efficiently encounter each others’s perspectives, identify where more information is needed, and speed the emergence of group coherence.`}</strong></p>
        <p parentName="li">{`   `}<strong parentName="p">{`Example:`}</strong>{` Polis was used by participants in a small city in Kentucky, United States, in advance of an in-person Town Hall meeting to develop a collective understanding of what was driving conflict in their municipality. The resulting opinion map and public data creates a group self-awareness strong enough to reject the divisive language that was being used as a smokescreen by elected officials seeking to avoid taking popular actions contrary to the will of their donors and political bosses. `}<a parentName="p" {...{
            "href": "https://compdemocracy.org/Case-studies/2018-kentucky/"
          }}>{`Read more`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Enable distributed deliberation`}</strong>{`
The public conversation can be conveyed coherently by Polis into formal deliberative spaces, surpassing previous size limits on participation. Assemblies usually have built-in feedback loops with decision makers and/or stakeholders, but rarely do they have the ability to canvas top of mind opinion, hear from individuals or groups, and problem solve directly with the broader public. Polis can collect and cluster input from more people than can be brought together physically in-person or remotely into a synchronous deliberative situation. Consider procuring a representative sample of people to respond to the Polis to fully avoid self-selection bias throughout the entirety of your assembly process. `}<strong parentName="p">{`Use Polis to further strengthen the democratic development of citizen recommendations.`}</strong>{` `}</p>
        <p parentName="li">{`   `}<strong parentName="p">{`Example:`}</strong>{` In vTaiwan, during synchronous meetings held in a mixed reality environment, an augmented facilitator is able to move statements between remote and in-person participants. `}<a parentName="p" {...{
            "href": "https://compdemocracy.org/Case-studies/2014-vTaiwan/"
          }}>{`Read more`}</a>{`
`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Support distributed review of text`}</strong>{`
While members of a citizen assembly are generating, editing, or revising a document such as a set of recommendations or even a Constitution, a Polis conversation can be dedicated to each section to serve as a container in which discussion can range far and deep, with confidence that Polis will do the work of surfacing important, consensed-upon points for inclusion in the final document. This can be used in combination with distributed deliberation (#5). For writing that is nearing its final form, facilitators can help formulate group-authored statements into more natural phrasing for use as seed statements. During a final review of text, consider turning off the ability for participants to add their own statements in order to focus attention on the proposed final texts only. `}<strong parentName="p">{`Use Polis to host dedicated conversations for each section of a document being generated, edited, or revised.`}</strong></p>
        <p parentName="li">{`   `}<strong parentName="p">{`Example:`}</strong>{` members of Austria’s National Climate Assembly, Der Klimarat, used Polis’ “seed statements” feature to distribute their preliminary policy statements for feedback from thousands of Austrians before finalizing their policy recommendations to Parliament. `}<a parentName="p" {...{
            "href": "https://compdemocracy.org/Case-studies/2022-Austria-Klimarat/"
          }}>{`Read more`}</a></p>
        <p parentName="li">{`   `}<strong parentName="p">{`Example:`}</strong>{` 16,000 people in Uruguay used Polis to look within a national referendum that combined 150 policy positions in a single yes/no vote, in order to go topic by topic to understand how these issues could best be unbundled. `}<a parentName="p" {...{
            "href": "https://compdemocracy.org/Case-studies/2021-Uruguay-national-referendum/"
          }}>{`Read more`}</a>{`
`}</p>
      </li>
    </ol>
    <h3>{`Conclusion:`}</h3>
    <p>{`Through these six strategies, Polis can enhance the democratic legitimacy generated by a citizens assembly.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      